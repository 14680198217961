html, body, div, span, applet, object, iframe,
blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.App {
  font-family: 'Source Sans Pro', sans-serif; }

.content {
  padding-top: 87px; }

.btn {
  font-weight: 700; }

.button {
  padding: 12px 20px;
  background: none;
  border: #27c1ce 1px solid;
  color: #27c1ce; }

.button:hover {
  background: #27c1ce;
  color: white; }

.buttons:active, .buttons:focus {
  background: #003e81; }

.button-fill {
  background: #27c1ce;
  color: white;
  display: flex;
  align-items: center;
  border: #27c1ce 1px solid; }

.button-fill:hover, .button-fill:focus {
  background: none;
  border: #27c1ce 1px solid;
  color: #27c1ce; }

.active-button, .active-button:hover, .active-button:focus {
  background: #1b858f;
  color: white; }

h1, h2, h3, h4, h5, h6, button, input, a {
  font-family: 'Roboto', sans-serif, Arial; }

.big-heading {
  font-size: 3rem;
  color: #444545;
  font-weight: 700; }

.sub-heading {
  font-size: 1.5rem; }

.color-text {
  color: #27c1ce;
  margin-top: 2rem; }

p, .secondary-text {
  line-height: 1.5; }

.paragraph {
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.5; }

.background-section {
  background-color: #eefafb;
  background-image: url("/public/media/hand-top.png");
  background-repeat: no-repeat;
  background-position: top left; }

.about-projects-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem; }
  .about-projects-section .projects-section-description {
    width: 100%; }
  @media only screen and (min-width: 950px) {
    .about-projects-section .projects-section-description {
      width: 75%; } }

.switch-methods-container {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .switch-methods-container button {
    margin-bottom: 1rem; }
  @media only screen and (min-width: 750px) {
    .switch-methods-container {
      width: 70%;
      margin: 0 auto;
      flex-direction: row;
      justify-content: center; }
      .switch-methods-container button {
        margin: 0 1rem 0; } }

.donate-page-button {
  width: 100%; }
  @media only screen and (min-width: 750px) {
    .donate-page-button {
      width: auto; } }

.donate-page-title {
  margin-bottom: 3rem; }

.project-description {
  font-size: 16px; }

.white-heading {
  color: white; }

.request-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .request-container .request-box {
    margin-left: 20px;
    margin-bottom: 30px; }
    .request-container .request-box img {
      width: 220px;
      box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0531481), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0425185), 0px 20px 13px rgba(0, 0, 0, 0.035), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519); }
  @media only screen and (max-width: 500px) {
    .request-container {
      flex-direction: column; }
      .request-container .request-box {
        margin-left: 0px;
        width: 100%; }
        .request-container .request-box img {
          width: 75%; } }
